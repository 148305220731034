/**
 * ログアウトコントロール用middleware
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isSignedIn } = useCognitoAuth();

  const signedIn = await isSignedIn();
  if (!signedIn) {
    return navigateTo('/mypage/sign-out/complete');
  }
});
